<template>
    <div class="row">

        <div class="col-sm-6" style="text-align: left;">
            <p><span>Merging accounts combines the logins, contact methods, and enrollments for two accounts.</span></p>

            <p><span>If the account being merged is enrolled in a course that the account it is being merged into is not enrolled in, grade information will be transferred to the merged account. If both accounts are enrolled in the same course, grade information from the account being merged will not be transferred.</span>
            </p>

            <p><span>After the accounts have been merged, the user can use the logins that originated from either account to view all their course enrollments within the same account.</span>
            </p>
        </div>
        <div class="col-sm-3 offset-3">

            <form @submit.prevent="searchUsers">
                <div style="text-align: left;">
                    <label>Search</label>
                    <input class="form-control" v-model="searchText" type="text" spellcheck="false"
                           placeholder="Name, Email, Organization"/>
                </div>
                <div style="text-align: right;">
                    <button type="button" class="button btn-primary bi-search" @click="searchUsers">Search</button>
                </div>
            </form>
        </div>

    </div>
    <div class="card" style="border: 1px solid black; border-radius: 0; padding: 1%;">
        <div class="row">
            <h3 style="font-weight: bold;">Merging</h3>
            <div class="col-sm " style="text-align: left;">
                <h6>Name: {{ userBeingMerged.firstName }} {{ userBeingMerged.lastName }}</h6>
                <h6>Email: {{ userBeingMerged.email }}</h6>
                <h6>Position: {{ userBeingMerged.positionName }}</h6>
            </div>
            <div class="col-sm" style="text-align: left;">
                <h6>ESU: {{ userBeingMerged.esuName !== null ? userBeingMerged.esuName : "No ESU" }}</h6>
                <h6>District: {{
                    userBeingMerged.districtName !== null ? userBeingMerged.districtName : "No District"
                    }}</h6>
                <h6>School: {{ userBeingMerged.schoolName !== null ? userBeingMerged.schoolName : "No School" }}</h6>
                <h6>Outstate Organization: {{
                    userBeingMerged.inNebraska ? "Not Outstate" : userBeingMerged.outStateOrgName
                    }}</h6>
            </div>
        </div>
    </div>
    <div class="container-lg mt-2" v-if="isTableLoaded">
        <div class="row">
            <div class="col-sm-3 offset-9">
                <input class="form-control mb-4" v-model="filterText" placeholder="Filter (name, email, institution)"
                       style="height: 41px"/>
            </div>
        </div>
        <MDBDatatable :dataset="userList" striped hover clickable-rows :search="filterText"
                      :maxHeight="635" fixed-header :entries="25"/>
    </div>
</template>

<script>
import {ref, computed, onBeforeMount} from "vue";
import axios from "axios";
import store from "@/store";
import {searchForUser} from "@/Helpers/ApiCalls/UserAPICalls";
import {API_URL} from "../../../Constants";
import {MDBDatatable} from "mdb-vue-ui-kit";

export default {
	name: "UserMergeSearchV2",
	components: {MDBDatatable},
	props: {
		userBeingMergedId: String
	},
	setup(props) {
		const searchText = ref("");
		const filterText = ref("");
		const authToken = store.getters['auth/authenticated'];
		const userBeingMerged = ref({});
		const userList = ref({
			columns: [
				{label: "Name", field: "name"},
				{label: "Merge?", field: "merge"},
				{label: "Email", field: "email"},
				{label: "ESU", field: "esu"},
				{label: "District", field: "district"},
				{label: "School", field: "school"},
				{label: "Outstate Organization", field: "educationalOrganization"},
				{label: "State", field: "state"},
				{label: "Country", field: "country"},
				{label: "Registration Date", field: "registrationDate"},
				{label: "Role", field: "position"},
			],
			rows: []
		});
		const isTableLoaded = ref(false);

		onBeforeMount(() => {
			getUserBeingMerged();
		})

		async function getUserBeingMerged() {
			let get_uri = API_URL + "/user/byId?_idUser=" + parseInt(atob(props.userBeingMergedId));
			await axios.get(get_uri, {
				headers: {
					"Authorization": "Bearer " + authToken.value
				}
			}).then((result) => {
				userBeingMerged.value = result.data;
			})
		}

		async function searchUsers() {
			isTableLoaded.value = false;
			await axios.get(searchForUser(), {
				params: {
					keyword: searchText.value
				},
				headers: {
					"Authorization": "Bearer " + authToken.value
				}
			}).then((result) => {
				userList.value.rows = setUserObjects(result.data);
			}).finally(() => {
				isTableLoaded.value = true;
			})
		}

		function setUserObjects(userList) {
			let userObjList = [];
			for (let i = 0; i < userList.length; i++) {
				let enabled = userList[i]['accountEnabled'] !== null ? userList[i]['accountEnabled'] : false;
				let account_status = enabled ? 'Active' : 'Inactive';
				// Exclude the user who will be merged
				if (userList[i]['idUser'] !== parseInt(atob(props.userBeingMergedId))) {
					let user = {
						name: `<span style="display: none">${userList['firstName']} ${userList['lastName']}</span> <a href="/viewUser/${btoa(userList[i]['idUser'])}">${userList[i]['firstName'] + " " + userList[i]['lastName']}</a>`,
						email: userList[i]['email'],
						state: userList[i]['stateName'],
						country: userList[i]['countryName'],
						esu: userList[i]['esuName'],
						district: userList[i]['districtName'],
						accountStatus: account_status,
						registrationDate: userList[i]['registrationDate'] !== null ? new Date(userList[i]['registrationDate']).toLocaleDateString() : "",
						idUser: btoa(userList[i]['idUser']),
						position: userList[i]['positionName'],
						educationalOrganization: (userList[i]['outStateOrgName'] !== null && userList[i]['outStateOrgName'] !== undefined)
							? userList[i]['outStateOrgName'] : "",
						canvasId: userList[i]['canvasId'] !== null ? userList[i]['canvasId'] : -1,
						merge: `<a href="/user/merge/${btoa(userList[i]['idUser'])}/remove/${props.userBeingMergedId}">Merge ${userList[i]['firstName']} ${userList[i]['lastName']}</a>`
					}
					userObjList.push(user);
				}
			}
			return userObjList;
		}

		return {
			filterText,
			userBeingMerged,
			userList,
			searchText,
			isTableLoaded,
			searchUsers
		}
	}
}
</script>

<style scoped>
span {
    color: black;
}
</style>